import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeBackground from "./Home/Background.png";
import TopShadowImage from "./Home/topShadow.png";
import { PRIMARY, GRAY } from "../utils/colors";
import Button from "../components/shared/Button";
import Link from "../components/shared/Link";
import ScreenShot from "./Home/screenshot.png";
import Paths from "./curriculum";
import { graphql } from "gatsby";

const TopShadowImg = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  opacity: 0.5;
`;

const HomeBgImg = styled.div`
  position: absolute;
  top: ${({ $top }) => $top || "50px"};
  z-index: -1;
  width: 100%;
  background-repeat: repeat-y;
  background-image: url(${HomeBackground});
  height: 2820px;
  background-size: contain;
`;

const Header = styled.h1`
  font-size: 50px;
  line-height: 60px;
  margin-top: 40px;

  span {
    color: ${PRIMARY};
  }
`;

const Footer = styled.footer`
  font-size: 16px;
  text-align: center;
`;

const H2 = styled.h2`
  font-size: 30px;
  line-height: 60px;
  margin-top: 20px;
  max-width: 1000px;
  margin: auto;
  color: ${GRAY};
`;

const LeftHero = styled.div`
  max-width: 500px;
  margin-top: 170px;
`;

const CenterHero = styled.div`
  text-align: center;
`;

const SignupButton = styled(Button)`
  margin: 40px;
  width: 200px;
`;

const HomepageWrapper = styled.div`
  margin: 120px auto;
  background: white;
  width: 500px;
  boxshadow: 0px 0px 4px silver;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout
      maxWidth="1300"
      theme="home"
      otherChildren={
        <>
          {" "}
          <TopShadowImg src={TopShadowImage} />{" "}
          <HomeBgImg src={HomeBackground} />{" "}
        </>
      }
    >
      <Seo title="Home" />
      <CenterHero>
        <Header>
          {" "}
          The Faster Way to Learn <span> React </span>
        </Header>
        <H2>Tons of examples. Easy to access.</H2>
        <Link to="/join">
          <SignupButton> Start now </SignupButton>
        </Link>
        <img src={ScreenShot} />
        {/*<div*/}
        {/*  style={{*/}
        {/*    position: "absolute",*/}
        {/*    fontSize: "32px",*/}
        {/*    marginLeft: "32px",*/}
        {/*    fontWeight: "bold",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {" "}*/}
        {/*  <div>Learn here</div>*/}
        {/*  <div style={{ fontSize: "18px", color: "grey" }}>*/}
        {/*    {" "}*/}
        {/*    Easy to follow text instructions and teachings{" "}*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    fontSize: "32px",*/}
        {/*    marginLeft: "32px",*/}
        {/*    fontWeight: "bold",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {" "}*/}
        {/*  <div>Code there</div>*/}
        {/*  <div style={{ fontSize: "18px", color: "grey" }}>*/}
        {/*    {" "}*/}
        {/*    Easy to follow text instructions and teachings{" "}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </CenterHero>
      <CenterHero style={{ marginTop: "172px", marginBottom: "60px" }}>
        <Header>Our curriculum</Header>
      </CenterHero>
      <Paths data={data} homepagePreview />
      <HomepageWrapper>
        <CenterHero
          style={{
            paddingTop: "40px",
            paddingBottom: "20px",
          }}
        >
          <H2>Ready to start?</H2>
          <Link to="/join">
            <SignupButton> Start now </SignupButton>
          </Link>
        </CenterHero>
      </HomepageWrapper>
      <Footer>©React School {new Date().getFullYear()}</Footer>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allPath(limit: 5) {
      edges {
        node {
          id
          title
          slug
          visibility
          description
          lessonCount
          access
        }
      }
    }
  }
`;

export default IndexPage;
